import HeroImage from './../assets/img/portrais.png'

import JavaScript from './../assets/img/js.png'
import PHP from './../assets/img/php.png'
import Python from './../assets/img/py.png'
import Java from './../assets/img/java.png'
import Dart from './../assets/img/dart.png'
import Sql from './../assets/img/sql.png'
import Batch from './../assets/img/bat.png'
import Shell from './../assets/img/sh.png'

import Express from './../assets/img/express.png'
import NestJs from './../assets/img/nest.png'
import Flutter from './../assets/img/flutter.png'
import ReactJS from './../assets/img/react.png'
import Angular from './../assets/img/angular.png'
import Flask from './../assets/img/flask.png'


import Nootty from './../assets/img/Nootty-Logo.webp'
import Keepen from './../assets/img/Keepen-Logo.webp'
import ArtistFalcrum from './../assets/svg/artist-falcrum.svg'
import DegreeDealers from './../assets/svg/logo-dd.svg'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

export default function Home() {
  useEffect(() => {
    const card = document.querySelector('.hover-3d');
    const THRESHOLD = 15;
    function handleHover(e) {

      const { clientX, clientY, currentTarget } = e;
      const { clientWidth, clientHeight, offsetLeft, offsetTop } = currentTarget;

      const horizontal = (clientX - offsetLeft) / clientWidth;
      const vertical = (clientY - offsetTop) / clientHeight;

      const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
      const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);

      card.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
    }

    function resetStyles(e) {
      card.style.transform =
        `perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
    }

    card.addEventListener("mousemove", handleHover);
    card.addEventListener("mouseleave", resetStyles);

    return () => {
      card.removeEventListener("mousemove", handleHover);
      card.removeEventListener("mouseleave", resetStyles);
    }
  });

  return (
    <>
      <section className='bg-dark' id='home'>
        <div className="container mx-auto px-6 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col gap-4 justify-center">
              <h1 className="text-5xl font-bold text-secondary">Bonjour, je suis <span className="text-terciary">Théau</span></h1>
              <p className="text-white text-lg ">Développeur web full stack</p>
              <Link to="/contact" className="btn-shadow-light">Contactez-moi</Link>
            </div>
            <div>
              <img src={HeroImage} alt="" className="w-full rounded-lg hover-3d hover:shadow-center" />
            </div>
          </div>
        </div>
      </section>
      <section id='about'>
        <div className="bg-light py-16">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-primary">A propos de moi</h2>
            <p className="text-dark mt-4">Bonjour et bienvenue sur mon portfolio !</p>
            <p className="text-dark mt-4">Je m'appelle Théau DUGUEPEROUX, développeur web et mobile en recherche d'une alternance et étudiant à My Digital School. Passionné par les nouvelles technologies, je crée des sites web et des applications mobiles performants et esthétiques. Avec une maîtrise de l'anglais, que je comprends et parle couramment, je suis capable de travailler sur des projets internationaux et de collaborer efficacement avec des équipes multiculturelles.</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
              <div>
                <h3 className="text-xl font-bold text-primary">Mon histoire</h3>
                <p className="text-dark mt-4">J'ai eu l'opportunité de faire un stage chez Nootty en 2021, où j'ai travaillé sur leur plateforme extranet pour les clients en utilisant React.js et Node.js. Cette expérience m'a permis de perfectionner mes compétences en développement front-end et back-end et de travailler sur des projets complexes en équipe.</p>
                <p className="text-dark mt-4">Par la suite, j'ai travaillé chez Keepen en tant que développeur web. J'ai d'abord contribué au développement de leur extranet de gestion des systèmes d'alarme, avec une technologie basée sur React, une API en Nest.js, et une base de données en MongoDB (NoSQL). Ensuite, j'ai travaillé sur une application mobile en Flutter, destinée à être utilisée sur des réseaux à faible débit, renforçant ainsi mes compétences en développement mobile.</p>
              </div>
              <div>
                <h3 className="text-xl font-bold text-primary">Mes objectifs</h3>
                <p className="text-dark mt-4">Mon objectif est de fournir des solutions innovantes et sur mesure pour répondre aux besoins de mes clients, tout en assurant la performance et la sécurité des plateformes développées. Je suis prêt à transformer vos idées en réalité et à vous accompagner dans la réalisation de vos ambitions digitales.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='skills'>
        <div className="container mx-auto px-6 py-16">
          <h2 className="text-3xl font-bold text-primary">Compétences</h2>
          <div className="grid justify-items-center grid-cols-2 md:grid-cols-4 gap-6 mt-6">
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center justify-between gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={JavaScript} alt="" className="w-16" />
              </div>
              <p className="text-dark">JavaScript</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={PHP} alt="" className="w-16" />
              </div>
              <p className="text-dark">PHP</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={Python} alt="" className="w-16" />
              </div>
              <p className="text-dark">Python</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={Java} alt="" className="w-16" />
              </div>
              <p className="text-dark">Java</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={Dart} alt="" className="w-16" />
              </div>
              <p className="text-dark">Dart</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={Sql} alt="" className="w-16" />
              </div>
              <p className="text-dark">SQL</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={Batch} alt="" className="w-16" />
              </div>
              <p className="text-dark">Batch</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={Shell} alt="" className="w-16" />
              </div>
              <p className="text-dark">Shell</p>
            </div>
          </div>
          <h2 className="text-3xl font-bold text-primary mt-12">Frameworks</h2>
          <div className="grid justify-items-center grid-cols-2 md:grid-cols-4 gap-6 mt-6">
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={Express} alt="" className="w-16" />
              </div>
              <p className="text-dark">Express</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={NestJs} alt="" className="w-16" />
              </div>
              <p className="text-dark">NestJs</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={Flutter} alt="" className="w-16" />
              </div>
              <p className="text-dark">Flutter</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={ReactJS} alt="" className="w-16" />
              </div>
              <p className="text-dark">ReactJS</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={Angular} alt="" className="w-16" />
              </div>
              <p className="text-dark">Angular</p>
            </div>
            <div className="bg-light w-full px-10 py-5 rounded-xl flex flex-col items-center gap-4">
              <div className='h-3/4 flex items-center'>
                <img src={Flask} alt="" className="w-16" />
              </div>
              <p className="text-dark">Flask</p>
            </div>
          </div>
        </div>
      </section>
      <section id='projects'>
        <div className="bg-light py-16">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-primary mb-5">Projets</h2>
            <div className="grid md:grid-cols-2 gap-4">
              <div className="bg-white rounded-xl p-5 flex flex-col gap-4 w-full">
                <div className='h-40'>
                  <img src={Nootty} alt="" className="w-full h-full object-contain" />
                </div>
                <div className=''>
                  <h3 className="text-xl font-bold text-primary">Nootty</h3>
                  <p className="text-dark">NoottyVR est une entreprise spécialisée dans la réalité virtuelle (VR), proposant des solutions immersives pour aider les entreprises à concrétiser leurs projets. Basée à Vern-sur-Seiche, elle permet aux clients de créer, envoyer et visualiser des projets en VR, offrant ainsi une expérience interactive et immersive pour diverses applications.</p>
                </div>
              </div>
              <div className="bg-white rounded-xl p-5 flex flex-col gap-4 w-full">
                <div className='h-40'>
                  <img src={Keepen} alt="" className="w-full h-full object-contain" />
                </div>
                <div className=''>
                  <h3 className="text-xl font-bold text-primary">Keepen</h3>
                  <p className="text-dark">Keepen est une entreprise spécialisée dans les solutions de protection et de sécurité simples et efficaces. Basée à Saint-Malo, Keepen propose des produits pour particuliers et gestionnaires de parc immobilier, tels que des systèmes de gestion des alarmes. Leur technologie innovante assure une sécurité optimale pour divers environnements.</p>
                </div>
              </div>
              <div className="bg-white rounded-xl p-5 flex flex-col gap-4 w-full">
                <div className='h-40'>
                  <img src={ArtistFalcrum} alt="" className="w-full h-full object-contain" />
                </div>
                <div className=''>
                  <h3 className="text-xl font-bold text-primary">Artist Falcrum</h3>
                  <p className="text-dark">Artist Falcrum est une plateforme inspirée d'Instagram, conçue pour permettre aux artistes de mettre en avant leurs œuvres comme dans une exposition virtuelle. Les artistes peuvent partager leur travail et recevoir des commentaires et des appréciations, offrant ainsi une vitrine numérique pour leurs créations artistiques.</p>
                </div>
              </div>
              <div className="bg-white rounded-xl p-5 flex flex-col gap-4 w-full">
                <div className='h-40'>
                  <img src={DegreeDealers} alt="" className="w-full h-full object-contain" />
                </div>
                <div className=''>
                  <h3 className="text-xl font-bold text-primary">Degree Dealers</h3>
                  <p className="text-dark">Degree Dealers est une plateforme dédiée aux étudiants, similaire à Fiverr, où ils peuvent proposer leurs compétences et services. Elle permet aux étudiants de monétiser leurs talents en offrant divers services freelances, tout en aidant les clients à trouver des prestataires qualifiés pour leurs projets académiques et professionnels.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}