import { BrowserRouter, Routes, Route } from "react-router-dom";

// layout
import Layout from "./Layout";

// pages
import Home from "./pages/Home.jsx";
import Contact from './pages/Contact.jsx';
import useDocumentTitle from "./useDocumentTitle.js";

export default function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route index element={<Page title="Home" component={<Home />} />} />
                    <Route path='/contact' element={<Page title="Contact" component={<Contact />} />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

function Page(props) {
    const titlePrefix = 'Théau 🤠 | '
    useDocumentTitle(`${titlePrefix}${props.title}`)
    return (
        <>
            {props.component}
        </>
    );
}