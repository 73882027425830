import { useEffect, useState } from "react";

export default function Contact() {
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setBlink(!blink);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [blink]);

  return (
    <section className="h-[calc(100vh-11rem)] flex items-center">
      <div className="container mx-auto px-6 py-20">
        <h1 className="text-4xl font-bold text-center">Contactez-moi</h1>
        <p className={`text-center ${blink ? "text-terciary" : "text-black"} text-5xl font-bold mt-4`}>WORK IN PROGRESS.</p>
        <form className="mt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input type="text" placeholder="Nom" className="w-full bg-gray-100 p-2 rounded-lg" />
            <input type="email" placeholder="Email" className="w-full bg-gray-100 p-2 rounded-lg" />
          </div>
          <textarea placeholder="Message" className="w-full bg-gray-100 p-2 rounded-lg mt-4"></textarea>
          <button type="submit" className="w-full bg-primary text-white p-2 rounded-lg mt-4">Envoyer</button>
        </form>
      </div>
    </section>
  );
}