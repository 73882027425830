import { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";

// import LogoLarge from './assets/img/logo-large.png';
import LogoSmall from './assets/img/logo-small.png';
import { HashLink } from "react-router-hash-link";

export default function Layout() {
    const [stickyClass, setStickyClass] = useState('relative');
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);

        return () => {
            window.removeEventListener('scroll', stickNavbar);
        };
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            windowHeight > 100 ? setStickyClass('fixed top-0 left-0 z-50') : setStickyClass('relative');
        }
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }


    return (
        <>
            <nav className={`bg-primary ${stickyClass} md:w-full w-screen md:hidden`}>
                <div className="container py-5 flex justify-between items-center">
                    <HashLink to="/#home"><img src={LogoSmall} alt="" className="h-16" /></HashLink>
                    <div className="relative">
                        <button onClick={toggleMenu} className="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                            </svg>
                        </button>
                        <div className={`bg-light ${showMenu ? 'block' : 'hidden'} absolute top-6 right-0 p-5 rounded-md`}>
                            <ul className="flex flex-col gap-4 items-center">
                                <li className="flex gap-4 items-center">
                                    <HashLink className="" to="/#home">Home</HashLink>
                                </li>
                                <li className="flex gap-4 items-center">
                                    <HashLink className="" to="/#about">A propos</HashLink>
                                </li>
                                <li className="flex gap-4 items-center">
                                    <HashLink className="" to="/#skills">Competences</HashLink>
                                </li>
                                <li className="flex gap-4 items-center">
                                    <HashLink className="" to="/#projects">Projet</HashLink>
                                </li>
                                <li className="flex gap-4 items-center">
                                    <Link className="btn" to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </nav>

            <nav className={`bg-primary ${stickyClass} md:w-full w-screen hidden md:block`}>
                <div className="container py-5 flex justify-between items-center">
                    <HashLink to="/#home"><img src={LogoSmall} alt="" className="h-16" /></HashLink>
                    <ul className="flex gap-4">
                        <li className="flex gap-4 items-center">
                            <HashLink className="text-white" to="/#home">Home</HashLink>
                        </li>
                        <li className="flex gap-4 items-center">
                            <HashLink className="text-white" to="/#about">A propos</HashLink>
                        </li>
                        <li className="flex gap-4 items-center">
                            <HashLink className="text-white" to="/#skills">Competences</HashLink>
                        </li>
                        <li className="flex gap-4 items-center">
                            <HashLink className="text-white" to="/#projects">Projet</HashLink>
                        </li>
                        <li className="flex gap-4 items-center">
                            <Link className="btn" to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
            </nav>

            <Outlet />

            <footer className="py-6 bg-dark">
                <div className="container mx-auto px-6 text-center text-white">
                    <p>&copy; 2024 Mon Portfolio. Tous droits réservés.</p>
                </div>
            </footer>
        </>
    )
};